<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    items: Object,
  },
  data() {
    return {
      chartData: {
        labels: this.items.label,
        datasets: [
          {
            label: "",
            tension: 0,
            data: this.items.value,
            backgroundColor: this.items.color,
            borderWidth: 0,
          },
        ],
      },
      options: {
        tooltips: {
          enabled: true, // hover시 데이터 tooltip
        },
        legend: { display: true },
        layout: {
          padding: {
            bottom: 20,
            // top: 20,
          },
        },
        // cutoutPercentage: 77, // 도넛차트로 만들기
        responsive: true, // 사이즈 container 반응형
        maintainAspectRatio: false,
      },
    };
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    },
    // items() {
    //   this.$data._chart.destroy();
    //   this.renderPieChart();
    // },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    renderPieChart() {
      this.renderChart(
        {
          labels: this.items.label,
          datasets: [
            {
              label: "",
              tension: 0,
              data: this.items.value,
              backgroundColor: this.items.color,
              borderWidth: 0,
            },
          ],
        },
        this.options
      );
    },
  },
};
</script>
