<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_01">
          <span style="margin-right: 0">
            <label>기간조회</label>
            <date-picker
              v-model="startDate"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="endDate"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
          </span>
          <span>
            <label style="opacity: 0">검색</label>
            <base-button type="primary" nativeType="submit">검색</base-button>
          </span>
        </div>
      </form>
    </section>

    <section>
      <div class="line_chart_box">
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">일별 커뮤니티 현황</h3>
          <span class="btn_right_mini">
            <base-button
              type="download"
              @click.prevent.stop="
                download(
                  'post/statistics-register-daily?download=xls',
                  '일별 게시글 현황',
                  'xls',
                  '',
                  'comment/statistics-register-daily?download=xls',
                  '일별 댓글 현황',
                  'like/statistics-register-daily',
                  '일별 좋아요 현황'
                )
              "
              :disabled="btnDisabled"
              >{{ downloadText }}</base-button
            >
          </span>
          <div class="line_chart_container">
            <div v-if="community.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!community.empty">
              <multi-line-chart
                :label="communityLabel"
                :date="community.date"
                :data="community.data"
              ></multi-line-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">일별 커뮤니티 신고 현황</h3>
          <span class="btn_right_mini">
            <base-button
              type="download"
              @click.prevent.stop="
                download(
                  'report/statistics-register-daily?download=xls',
                  '일별 커뮤니티 신고 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >{{ downloadText }}</base-button
            >
          </span>
          <!-- TODO 리포트 API 수정 및 확인 -->
          <div class="line_chart_container">
            <div v-if="report.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!report.empty">
              <multi-line-chart
                :date="report.date"
                :data="report.data"
              ></multi-line-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="pie_chart_box">
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">게시글 성별</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'post/statistics-register-gender-daily?download=xls',
                  '게시글 성별 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="postGenderChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!postGenderChart.empty">
              <pie-chart :items="postGenderChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">게시글 국가</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'post/statistics-register-country-daily?download=xls',
                  '게시글 국가 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="postCountryChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!postCountryChart.empty">
              <pie-chart :items="postCountryChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">게시글 언어</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'post/statistics-register-lang-daily?download=xls',
                  '게시글 언어 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="postLangChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!postLangChart.empty">
              <pie-chart :items="postLangChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">게시글 연령</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'post/statistics-register-age-daily?download=xls',
                  '게시글 연령 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="postAgeChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!postAgeChart.empty">
              <pie-chart :items="postAgeChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="pie_chart_box">
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">댓글 성별</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'comment/statistics-register-gender-daily?download=xls',
                  '댓글 성별 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="commentGenderChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!commentGenderChart.empty">
              <pie-chart :items="commentGenderChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">댓글 국가</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'comment/statistics-register-country-daily?download=xls',
                  '댓글 국가 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="commentCountryChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!commentCountryChart.empty">
              <pie-chart :items="commentCountryChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">댓글 언어</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'comment/statistics-register-lang-daily?download=xls',
                  '댓글 언어 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="commentLangChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!commentLangChart.empty">
              <pie-chart :items="commentLangChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">댓글 연령</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'comment/statistics-register-age-daily?download=xls',
                  '댓글 연령 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="commentAgeChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!commentAgeChart.empty">
              <pie-chart :items="commentAgeChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="pie_chart_box">
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">좋아요 성별</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'like/statistics-register-gender-daily?download=xls',
                  '좋아요 성별 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="likeGenderChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!likeGenderChart.empty">
              <pie-chart :items="likeGenderChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">좋아요 국가</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'like/statistics-register-country-daily?download=xls',
                  '좋아요 국가 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="likeCountryChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!likeCountryChart.empty">
              <pie-chart :items="likeCountryChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">좋아요 언어</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'like/statistics-register-lang-daily?download=xls',
                  '좋아요 언어 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="likeLangChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!likeLangChart.empty">
              <pie-chart :items="likeLangChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">좋아요 연령</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'like/statistics-register-age-daily?download=xls',
                  '좋아요 연령 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="likeAgeChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!likeAgeChart.empty">
              <pie-chart :items="likeAgeChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="pie_chart_box">
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">신고 성별</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'report/statistics-register-gender-daily?download=xls',
                  '신고 성별 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="reportGenderChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!reportGenderChart.empty">
              <pie-chart :items="reportGenderChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">신고 국가</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'report/statistics-register-country-daily?download=xls',
                  '신고 국가 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="reportCountryChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!reportCountryChart.empty">
              <pie-chart :items="reportCountryChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_left">
          <h3 class="tbl_count_title">신고 언어</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'report/statistics-register-lang-daily?download=xls',
                  '신고 언어 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="reportLangChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!reportLangChart.empty">
              <pie-chart :items="reportLangChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
        <div class="tbl_top_right">
          <h3 class="tbl_count_title">신고 연령</h3>
          <span class="btn_right_mini">
            <base-button
              type="download_mini"
              @click.prevent.stop="
                download(
                  'report/statistics-register-age-daily?download=xls',
                  '신고 연령 현황',
                  'xls'
                )
              "
              :disabled="btnDisabled"
              >다운</base-button
            >
          </span>
          <div class="pie_chart_container">
            <div v-if="reportAgeChart.loading">
              <clip-loader color="#ff2c55">Loading</clip-loader>
            </div>
            <div v-else-if="!reportAgeChart.empty">
              <pie-chart :items="reportAgeChart.data"></pie-chart>
            </div>
            <div v-else>Empty</div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/**
 * vuex
 */
import { mapGetters } from "vuex";

/**
 * client
 */
import client from "api-client";

// chart
import MultiLineChart from "../../../components/chart/BaseMultiLineChart.vue";
import PieChart from "../../../components/chart/BasePieChart.vue";

import moment from "moment";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "StampDashboard",
  components: { MultiLineChart, PieChart },
  data() {
    /**
     * data
     */
    return {
      downloadText: "엑셀 다운로드",
      btnDisabled: false,
      startDate: "",
      endDate: "",
      communityLabel: ["게시글", "댓글", "좋아요"],
      // reportLabel: ["게시글", "댓글"],
      community: {
        loading: true,
        empty: true,
        date: [],
        data: [],
      },
      report: {
        loading: true,
        empty: true,
        date: [],
        data: [],
      },
      postGenderChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },

      postLangChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      postCountryChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      postAgeChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: [
            "#ef9a9a",
            "#f48fb1",
            "#ce93d8",
            "#b39ddb",
            "#c5cae9",
            "#eee",
          ],
        },
      },
      commentGenderChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      commentLangChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      commentCountryChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      commentAgeChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: [
            "#ef9a9a",
            "#f48fb1",
            "#ce93d8",
            "#b39ddb",
            "#c5cae9",
            "#eee",
          ],
        },
      },
      likeGenderChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      likeLangChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      likeCountryChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      likeAgeChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: [
            "#ef9a9a",
            "#f48fb1",
            "#ce93d8",
            "#b39ddb",
            "#c5cae9",
            "#eee",
          ],
        },
      },
      reportGenderChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      reportLangChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      reportCountryChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: ["#f48fb1", "#ce93d8", "#b39ddb", "#eee"],
        },
      },
      reportAgeChart: {
        loading: true,
        empty: true,
        data: {
          label: [],
          value: [],
          color: [
            "#ef9a9a",
            "#f48fb1",
            "#ce93d8",
            "#b39ddb",
            "#c5cae9",
            "#eee",
          ],
        },
      },
    };
  },
  created() {
    /**
     * created
     */
    if (!this.startDate && !this.endDate) {
      this.startDate = moment(new Date()).add(-29, "day").format("YYYY-MM-DD");
      this.endDate = moment(new Date()).format("YYYY-MM-DD");
    }
    this.$Progress.start();
    // console.log(client);
    this.fetch();
  },
  mounted() {
    /**
     * mounted
     */
    this.$Progress.finish();
  },
  computed: {
    /**
     * computed
     */
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /**
     * methods
     */

    download(url, name, type, params, url2, name2, url3, name3) {
      // this.downloadText = "다운로드 중";
      // this.btnDisabled = true;
      params = {
        page: this.currentPage,
        size: this.pageSize,
        startDate: this.$date(new Date(this.startDate)).format("YYYY-MM-DD"),
        endDate: this.$date(new Date(this.endDate)).format("YYYY-MM-DD"),
        download: "xls",
      };
      if (url && url2 && url3) {
        url = `${API_URL}${SERVICE_API_URL}/${url}`;
        url2 = `${API_URL}${SERVICE_API_URL}/${url2}`;
        url3 = `${API_URL}${SERVICE_API_URL}/${url3}`;
        File.download(url, name, type, params);
        File.download(url2, name2, type, params);
        File.download(url3, name3, type, params, (response) => {
          console.log(response);
          // if (response.status == 200) {
          this.btnDisabled = false;
          this.downloadText = "엑셀 다운로드";
          // }
        });
      }
      url = `${API_URL}${SERVICE_API_URL}/${url}`;
      File.download(url, name, type, params, (response) => {
        console.log(response);
        // if (response.status == 200) {
        this.btnDisabled = false;
        this.downloadText = "엑셀 다운로드";
        // }
      });
    },

    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },

    async fetchCommunityDaily() {
      let params = {
        startDate: this.$date(new Date(this.startDate)).format("YYYY-MM-DD"),
        endDate: this.$date(new Date(this.endDate)).format("YYYY-MM-DD"),
      };

      let post = [];
      let comment = [];
      let like = [];
      let date = [];

      await client.postRegisterDaily(params).then(
        (response) => {
          const { data } = response;
          const items = data.items;
          post = items.map((item) => item.cnt);
          date = items.map((item) => item.date);
        },
        () => {}
      );
      await client.commentRegisterDaily(params).then(
        (response) => {
          const { data } = response;
          const items = data.items;
          comment = items.map((item) => item.cnt);
        },
        () => {}
      );
      await client.likeRegisterDaily(params).then(
        (response) => {
          const { data } = response;
          const items = data.items;
          like = items.map((item) => item.cnt);
        },
        () => {}
      );

      this.community.date = date;
      this.community.data = [post, comment, like];
      this.community.loading = false;
      this.community.empty = false;
    },

    async fetch() {
      this.community.loading = true;
      this.report.loading = true;
      this.postGenderChart.loading = true;
      this.postCountryChart.loading = true;
      this.postLangChart.loading = true;
      this.postAgeChart.loading = true;
      this.commentGenderChart.loading = true;
      this.commentCountryChart.loading = true;
      this.commentLangChart.loading = true;
      this.commentAgeChart.loading = true;
      this.likeGenderChart.loading = true;
      this.likeCountryChart.loading = true;
      this.likeLangChart.loading = true;
      this.likeAgeChart.loading = true;
      this.reportGenderChart.loading = true;
      this.reportCountryChart.loading = true;
      this.reportLangChart.loading = true;
      this.reportAgeChart.loading = true;
      if (!this.startDate || !this.endDate) {
        alert("날짜를 선택해주세요");
      } else {
        let params = {
          startDate: this.$date(new Date(this.startDate)).format("YYYY-MM-DD"),
          endDate: this.$date(new Date(this.endDate)).format("YYYY-MM-DD"),
        };

        this.fetchCommunityDaily();

        await client.reportPostDaily(params).then(
          (response) => {
            const { data } = response;
            const items = data.items;
            const post = items.map((item) => item.cnt);
            this.report.data = [post];
            this.report.date = items.map((item) => item.date);
            this.report.loading = false;
            this.report.empty = false;
          },
          () => {
            this.report.empty = true;
          }
        );
        /*
        await client.reportCommentDaily(params).then(
          (response) => {
            const { data } = response;
            const items = data.items;
            this.report.comment = items.map((item) => item.cnt);
            this.reportCommentLoad = true;
          },
          () => {}
        );
  */
        await client.postGenderDaily(params).then(
          (response) => {
            const { data } = response;
            this.postGenderChart.data.label = data.items.map(
              (item) => item.label
            );
            this.postGenderChart.data.value = data.items.map(
              (item) => item.value
            );
            this.postGenderChart.loading = false;
            this.postGenderChart.empty = false;
          },
          () => {
            this.postGenderChart.empty = true;
          }
        );
        await client.postCountryDaily(params).then(
          (response) => {
            const { data } = response;
            this.postCountryChart.data.label = data.items.map(
              (item) => item.label
            );
            this.postCountryChart.data.value = data.items.map(
              (item) => item.value
            );
            this.postCountryChart.loading = false;
            this.postCountryChart.empty = false;
          },
          () => {
            this.postCountryChart.empty = true;
          }
        );
        await client.postLangDaily(params).then(
          (response) => {
            const { data } = response;
            this.postLangChart.data.label = data.items.map(
              (item) => item.label
            );
            this.postLangChart.data.value = data.items.map(
              (item) => item.value
            );
            this.postLangChart.loading = false;
            this.postLangChart.empty = false;
          },
          () => {
            this.postLangChart.empty = true;
          }
        );
        await client.postAgeDaily(params).then(
          (response) => {
            const { data } = response;
            this.postAgeChart.data.label = data.items.map((item) => item.label);
            this.postAgeChart.data.value = data.items.map((item) => item.value);
            this.postAgeChart.loading = false;
            this.postAgeChart.empty = false;
          },
          () => {
            this.postAgeChart.empty = true;
          }
        );
        await client.commentGenderDaily(params).then(
          (response) => {
            const { data } = response;
            this.commentGenderChart.data.label = data.items.map(
              (item) => item.label
            );
            this.commentGenderChart.data.value = data.items.map(
              (item) => item.value
            );
            this.commentGenderChart.loading = false;
            this.commentGenderChart.empty = false;
          },
          () => {
            this.commentGenderChart.empty = true;
          }
        );
        await client.commentCountryDaily(params).then(
          (response) => {
            const { data } = response;
            this.commentCountryChart.data.label = data.items.map(
              (item) => item.label
            );
            this.commentCountryChart.data.value = data.items.map(
              (item) => item.value
            );
            this.commentCountryChart.loading = false;
            this.commentCountryChart.empty = false;
          },
          () => {
            this.commentCountryChart.empty = true;
          }
        );
        await client.commentLangDaily(params).then(
          (response) => {
            const { data } = response;
            this.commentLangChart.data.label = data.items.map(
              (item) => item.label
            );
            this.commentLangChart.data.value = data.items.map(
              (item) => item.value
            );
            this.commentLangChart.loading = false;
            this.commentLangChart.empty = false;
          },
          () => {
            this.commentCountryChart.empty = true;
          }
        );
        await client.commentAgeDaily(params).then(
          (response) => {
            const { data } = response;
            this.commentAgeChart.data.label = data.items.map(
              (item) => item.label
            );
            this.commentAgeChart.data.value = data.items.map(
              (item) => item.value
            );
            this.commentAgeChart.loading = false;
            this.commentAgeChart.empty = false;
          },
          () => {
            this.commentAgeChart.empty = true;
          }
        );
        await client.likeGenderDaily(params).then(
          (response) => {
            const { data } = response;
            this.likeGenderChart.data.label = data.items.map(
              (item) => item.label
            );
            this.likeGenderChart.data.value = data.items.map(
              (item) => item.value
            );
            this.likeGenderChart.loading = false;
            this.likeGenderChart.empty = false;
          },
          () => {
            this.likeGenderChart.empty = true;
          }
        );
        await client.likeCountryDaily(params).then(
          (response) => {
            const { data } = response;
            this.likeCountryChart.data.label = data.items.map(
              (item) => item.label
            );
            this.likeCountryChart.data.value = data.items.map(
              (item) => item.value
            );
            this.likeCountryChart.loading = false;
            this.likeCountryChart.empty = false;
          },
          () => {
            this.likeCountryChart.empty = true;
          }
        );
        await client.likeLangDaily(params).then(
          (response) => {
            const { data } = response;
            this.likeLangChart.data.label = data.items.map(
              (item) => item.label
            );
            this.likeLangChart.data.value = data.items.map(
              (item) => item.value
            );
            this.likeLangChart.loading = false;
            this.likeLangChart.empty = false;
          },
          () => {
            this.likeLangChart.empty = true;
          }
        );
        await client.likeAgeDaily(params).then(
          (response) => {
            const { data } = response;
            this.likeAgeChart.data.label = data.items.map((item) => item.label);
            this.likeAgeChart.data.value = data.items.map((item) => item.value);
            this.likeAgeChart.loading = false;
            this.likeAgeChart.empty = false;
          },
          () => {
            this.likeAgeChart.empty = true;
          }
        );
        await client.reportGenderDaily(params).then(
          (response) => {
            const { data } = response;
            this.reportGenderChart.data.label = data.items.map(
              (item) => item.label
            );
            this.reportGenderChart.data.value = data.items.map(
              (item) => item.value
            );
            this.reportGenderChart.loading = false;
            this.reportGenderChart.empty = false;
          },
          () => {
            this.reportGenderChart.empty = true;
          }
        );
        await client.reportCountryDaily(params).then(
          (response) => {
            const { data } = response;
            this.reportCountryChart.data.label = data.items.map(
              (item) => item.label
            );
            this.reportCountryChart.data.value = data.items.map(
              (item) => item.value
            );
            this.reportCountryChart.loading = false;
            this.reportCountryChart.empty = false;
          },
          () => {
            this.reportCountryChart.empty = true;
          }
        );
        await client.reportLangDaily(params).then(
          (response) => {
            const { data } = response;
            this.reportLangChart.data.label = data.items.map(
              (item) => item.label
            );
            this.reportLangChart.data.value = data.items.map(
              (item) => item.value
            );
            this.reportLangChart.loading = false;
            this.reportLangChart.empty = false;
          },
          () => {
            this.reportCountryChart.empty = true;
          }
        );
        await client.reportAgeDaily(params).then(
          (response) => {
            const { data } = response;
            this.reportAgeChart.data.label = data.items.map(
              (item) => item.label
            );
            this.reportAgeChart.data.value = data.items.map(
              (item) => item.value
            );
            this.reportAgeChart.loading = false;
            this.reportAgeChart.empty = false;
          },
          () => {
            this.reportAgeChart.empty = true;
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
@import "../../../assets/scss/common.scss";
</style>
